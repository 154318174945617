/* admin */

/* common css */
a{
  text-decoration: none !important;
}
/* end of common css */
.adminheaderlogo {
  max-width: 55px;
  max-height: 55px;
}

.logo_sec_sidebar {
  justify-content: space-between;
  padding: 10px;
  min-width: 250px;
}

.btn:focus {
  box-shadow: unset !important;
}

.common_secondary_btn {
  background-color: #6c757d;
  border: 1px solid #6c757d;
  padding: 7px 16px;
  border-radius: 20px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  box-shadow: none !important;
}

.common_secondary_btn:hover {
  background-color: transparent;
  border: 1px solid #6c757d;
  padding: 7px 16px;
  color: white;
}

.common_yellow_btn {
  background-color: var(--themeclr) !important;
  border: 1px solid var(--themeclr) !important;
  padding: 7px 16px;
  border-radius: 20px;
  color: #000 !important;
  font-weight: 500;
  font-size: 14px;
  box-shadow: none !important;
}

.common_yellow_btn:hover {
  background-color: transparent !important;
  border: 1px solid var(--themeclr) !important;
  color: var(--themeclr) !important;
}

.common_yellow_btn:focus {
  color: #000;
}

.common_yellow_secondary_btn {
  background-color: transparent !important;
  border: 1px solid var(--themeclr) !important;
  color: var(--themeclr);
  padding: 7px 16px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  box-shadow: none !important;
}

.common_yellow_secondary_btn:hover {
  background-color: var(--themeclr) !important;
  border: 1px solid var(--themeclr) !important;
  color: #000;
}

.mobiletog {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  background: var(--themeclr);
  text-decoration: none !important;
  border-radius: 50%;
}

#dashboard {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.sidebar .menus {
  margin-bottom: 12px;
  margin-right: 15px;
}

.hidesidebar #sidebar {
  width: 0;
  transition: 0.5s;
}

#sidebar {
  width: 250px;
  position: absolute;
  background-color: #090808;
  transition: 0.5s;
  height: calc(100vh - 0px);
  z-index: 999;
  position: fixed;
  margin-top: 0px;
  box-shadow: rgb(0 0 0 / 4%) 0px -21px 10px !important;
}

.sidelist {
  margin-bottom: 45px;
}

.sidebar .sidelist {
  height: calc(100vh - 328px);
  padding-left: 0;
  margin-top: 22px;
  overflow-y: auto;
}

.sidebar.adminside .sidelist {
  /* overflow-y: auto; */
  min-height: calc(100vh - 210px);
  /* min-height: calc(100vh - 380px); */
}

.sidebar.adminside .sidelist::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* border-radius: 10px; */
  background-color: #090808 !important;
}

.sidebar.adminside .sidelist::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #090808 !important;
}

.sidebar.adminside .sidelist::-webkit-scrollbar-thumb {
  /* border-radius: 10px; */
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #090808 !important;
}

.sidebar {
  position: relative;
}

.sidebar .menus a.active,
.sidebar .menus a:hover {
  background: #171717;
  position: relative;
  border-left: 2px solid var(--themeclr);
}

.sidebar .menus a.active .lights,
.sidebar .menus a:hover .lights {
  display: none;
}

.sidebar .menus a.active .darks,
.sidebar .menus a:hover .darks {
  display: block;
}

.sidebar .menus a .darks {
  display: none;
}

.sidebar .menus a {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  border-radius: 0px 50px 50px 0px;
  border-left: 2px solid transparent;
  text-decoration: none;
  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 11px;
  padding-bottom: 12px;
  padding-left: 15px;
}

.menus img {
  margin-right: 16px;
  max-width: 20px;
}

.sidebar_ad_div a {
  display: flex;
  border: 1px solid #f7bd025e;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.sidebar_ad_div a:hover {
  border: 1px solid var(--themeclr);
  background-color: var(--themeclr);
}

.sidebar_ad_div a:hover svg {
  color: #000;
}

.sidebar_ad_div a svg {
  color: #fff;
  width: 16px;
  height: 16px;
}

.hidesidebar .sidebar_ad_div {
  display: none;
}

.headandcont {
  /* width: 100vw; */
  width: calc(100vw - 10px);
}

.adminheader,
.adminheader #homeheader,
.adminheader #homeheader .navbar-light {
  min-height: 80px;
}

.adminheader {
  background: #000;
  height: 65px;
  position: fixed;
  width: 100%;
  z-index: 99;
  padding-left: 20px;
  transition: 0.5s;
  padding-right: 18px;
  border-bottom: 1px solid #70707033;
  /* box-shadow: 0 3px 10px #34333396; */
}

.hidesidebar .adminheader,
.hidesidebar .cont {
  margin-left: 0px;
  padding-left: 20px;
  transition: 0.5s;
  border-radius: 0;
}

.navbar-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: var(--themeclr);
  background: var(--themeclr);
  text-decoration: none !important;
  border-radius: 50%;
  box-shadow: 0 0 0 0rem !important;
}

/* .navbar-toggler-icon
{
  background-image: none !important;
  width: unset !important;
  height: unset !important;
}
.navbar-toggler-icon:before {
  content: "\f0c9";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  color: #571E92 !important;
} */
#dashboard .navbar-brand {
  display: none;
}

.menu_mobilalign .mobileadmin {
  display: none;
}

.custom_navbar_moveto_top {
  /* position: relative; */
  /* top: -5.5px; */
}

.tronbtn {
  background-color: var(--themeclr);
  border: 1px solid var(--themeclr);
  padding: 7px 16px;
  border-radius: 20px;
  color: #000;
  font-weight: 500;
  font-size: 14px;
}

.tronbtn:hover {
  background-color: var(--themeclr);
  color: #000;
  border: 1px solid var(--themeclr);
}

.tronbtn:focus {
  background-color: var(--themeclr);
  color: #000;
  border: 1px solid var(--themeclr);
}

.tronbtn:active {
  background-color: var(--themeclr);
  color: #000;
  border: 1px solid var(--themeclr);
}

.allheaderlist {
  display: flex;
  align-items: center;
  gap: 20px;
}

.tron {
  /* width: 18px;
  height: 18px;
  object-fit: contain; */
}

.wallet_border {
  border: 1px solid #707070;
  border-radius: 20px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  /* margin-left: 12px; */
}

.wallet_border .trx {
  border-right: 1px solid #707070;
  padding-right: 12px;
}

.trxtxt {
  font-size: 14px;
}

.cont {
  margin-left: 250px;
  transition: 0.5s;
  padding-right: 18px;
  border-radius: 15px 0 0 0;
  background-color: var(--graybg);
  min-height: calc(100vh - 116px);
  margin-top: 65px;
}

.hidesidebar .adminheader,
.hidesidebar .cont {
  margin-left: 0px;
  padding-left: 20px;
  transition: 0.5s;
  border-radius: 0;
}

@media only screen and (min-width: 1200px) {
  .recordes_sec_padd .cont_padding {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .cont_padding {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
}

@media screen and (max-width: 991px) {
  .showsidebar #sidebar {
    width: 0;
  }

  .adminheader,
  .cont {
    margin-left: 0;
    border-radius: 0;
  }

  #dashboard .navbar-brand {
    display: block;
  }

  .navbar-brand {
    display: block;
    margin-top: -5px;
  }

  .menu_mobilalign .mobileadmin {
    display: block;
  }

  .sidebar.adminside .sidelist {
    min-height: 100vh;
  }

  .menu_mobilalign.align-items-center {
    align-items: flex-start !important;
  }

  .adminmobilelogo {
    max-width: 55px;
  }

  .showsidebar #sidebar {
    left: -200px;
  }

  .sidebar .mobiletog {
    display: none;
  }

  #basic-navbar-nav {
    padding-bottom: 20px;
    position: fixed;
    background: #090808;
    /* background: #0000; */
    min-width: 250px;
    max-width: 250px;
    top: 80px;
    left: 0px;
    z-index: -1;
    overflow-y: auto;
    box-shadow: rgb(0 0 0 / 4%) 0px -21px 10px !important;
    height: calc(100% - 65px);
    transition: 0.5s;
    margin-top: -12px;
  }

  .adminheader {
    background: #090808;
  }

  .mobileadmin {
    display: flex;
    flex-direction: column;
    /* padding-left: 15px; */
    width: calc(100% - 10px);
    padding-top: 20px;
    transition: 0.5s;
  }

  .sidebar .menus {
    margin-top: 15px;
  }

  .navbar-toggler {
    margin-top: -5px;
  }

  .adminheader,
  .adminheader #homeheader,
  .adminheader #homeheader .navbar-light {
    min-height: 85px;
  }

  .sidebar .menus a {
    margin-bottom: 15px;
  }
}

/* 22-11-23 sakthi */

@media (max-width: 991px) {
  .allheaderlist {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }

  .custom_transaction_drop .dropdown-menu.show {
    transform: translate(0px, 0px) !important;
  }
}

.admin_header_sec .header_rgt_search_sec {
  display: flex;
  align-items: center;
  /* margin-left: 30px; */
  gap: 13px;
}

.admin_header_sec .header_rgt_search_sec .search_btn {
  border: 1px solid #707070;
  border-radius: 20px;
  padding: 7px 9px;
  /* margin-left: 12px; */
  cursor: pointer;
}

.admin_header_sec .header_rgt_search_sec .notification_btn .dropdown-toggle {
  border: 1px solid #707070;
  border-radius: 20px;
  background-color: transparent !important;
  padding: 7px 9px;
  /* margin-left: 12px; */
}

.admin_header_sec .header_rgt_search_sec .login_out_btn .dropdown-toggle {
  background-color: var(--themeclr);
  color: #000;
  border: none !important;
  border-radius: 20px;
  padding: 7px 9px;
  /* margin-left: 12px; */
}

.admin_header_sec .login_out_btn .dropdown-toggle::after,
.admin_header_sec .notification_btn .dropdown-toggle::after,
.admin_header_sec .custom_search_drop .dropdown-toggle::after {
  display: none !important;
}

.card_section .cmn_card {
  background-color: #090808;
  width: 100% !important;
}

.card_section .cmn_card .card-body {
  padding: 0px;
  border-radius: 10px;
}

.card_section .header {
  background-color: #171717;
  padding: 16px 24px;
  border-radius: 10px;
  color: white;
  font-size: 16px;
}

.card_section .content_sec {
  padding: 21px 24px;

  /* padding: 0px 24px; */
  min-height: 120px;
  /* display: flex; */
  /* align-items: center; */
}

.dashboard_card.card_section .header {
  color: #d1d1d1 !important;
}

.card_section .cmn_card .total_count {
  color: white;
  font-size: 25px;
  font-weight: 600;
}

.card_section .cmn_card .cnt {
  background: #092007;
  border-radius: 7px;
  color: #4af702;
  padding: 4px 16px;
}

.card_section .cmn_card .cnt svg {
  color: #4af702;
  position: relative;
  top: -2px;
}

.card_section .cmn_card .cnt .no {
  font-size: 16px;
}

.my_current_plan .card_section .header {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}

.my_current_plan .card_section .plan_completed_sec {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  min-height: 120px;
}

.my_current_plan .card_section .plan_completed_sec .completed {
  background-color: #f7bd02;
}

.my_current_plan .card_section .plan_completed_sec .in_completed {
  background-color: #171717;
}

.my_current_plan .card_section .plan_completed_sec .cmn_plan_count {
  width: 38px;
  height: 38px;
  border-radius: 24px;
}

.content_sec .btn_sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.content_sec .btn_sec .preview_btn {
  padding: 8px 28px !important;
  border-radius: 10px !important;
  /* height: 40px; */
}

.card .plangrp {
  width: 60px !important;
  height: 60px !important;
}

.my_current_plan .card_section .content_sec {
  padding: 21px 24px 30px 24px !important;
}

.blur_card_sec {
  filter: blur(10px);
  pointer-events: none;

  /* background: #090808 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10) */
}

.my_current_plan .locked_plan_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.locked_plan_content .plan_level {
  color: white;
  font-size: 24px;
}

.my_current_plan .card-body {
  position: relative;
}

.locked_plan_content .lock_logo {
  width: 40px !important;
  height: 40px !important;
  object-fit: contain;
}

.plan_completed_sec span {
  /* margin-bottom: 1.8rem; */
  /* margin-right: 1.8rem; */
}

.dashboard_sec_content .heading {
  font-size: 24px;
  color: white;
  font-weight: 600;
}

/* plan card */
.dashboard_sec_content .head_section {
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 500;
  margin-top: 20px;
}

.dashboard_sec_content .sub_heding {
  color: var(--white);
  font-size: 22px;
  font-weight: 500;
}

.dashboard_sec_content .sub_heding_val {
  color: var(--yellow);
  font-size: 22px;
  font-weight: 500;
}

.dashboard_sec_content .personal_link_sec {
  display: flex;
  align-items: center;
  justify-content: end;
}

.plan_card_sec .plan_collection .card {
  background-color: var(--card-body);
  border-radius: 10px;
}

.plan_card_sec .level_card {
  background-color: var(--card-header) !important;
}

.plan_card_sec .head_sec .sml_circle {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: var(--yellow);
}

.plan_card_sec .body_sec {
  /* margin: 2rem 0px; */
  /* height: 100px; */
  min-height: 130px;
  display: flex;
  align-items: center;
  /* gap: 25px; */
}

.plan_custom_navi .user_icon {
  /* width: 30px; */
  /* height: 30px; */
  height: 18px;
  width: 18px;
}

.single_user_icon {
  width: 250x;
  height: 20px;
}

.plan_custom_navi .reload_icon {
  width: 28px;
  height: 28px;
}

.plan_card_sec .body_sec .list {
  display: flex;
  align-items: center;
  gap: 25px;
}

.plan_card_sec .body_sec .cmn_bg_color {
  width: 40px;
  display: inline-block;
  height: 40px;
  border-radius: 24px;
  background-color: #2f2f2f;
}

.plan_card_sec .body_sec .completed {
  background-color: var(--yellow) !important;
}

.plan_card_sec .body_sec .cmn_bg_color.red_bg {
  background: red !important;
  font-size: 12px;
  color: #fff;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.plan_card_sec .locked_plan {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.plan_card_sec .locked_plan .lock_logo {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.plan_card_sec .foot_partner_level_collec {
  border: 0.5px solid #707070;
  border-radius: 10px;
  background-color: var(--grey1);
  padding: 18px 25px;
  display: flex;
  justify-content: space-around;
  gap: 20px;
  color: #d1d1d1;
  margin: 2rem 0px;
  flex-wrap: wrap;
}

.plan_card_sec .foot_partner_level_collec .direct_partner {
  min-width: 25px;
  min-height: 25px;
  background-color: var(--yellow);
  display: inline-block;
  border-radius: 20px;
}

.plan_card_sec .card_custom_pad {
  padding: 1.8rem;
  min-height: 320px;
}

.plan_card_sec .lock_lottie {
  width: 200px;
  height: 200px;
}

.plan_card_sec .unlock_btn {
  padding: 10px 40px !important;
  border-radius: 10px;
}

.plan_card_sec .locked_plan .plan_level {
  color: var(--white);
  font-size: 25px;
  font-weight: 500;
}

.dashboard_sec_content .user_based_search {
  border: 0.20000000298023224px solid #222;
  border-radius: 10px;
  background-color: #090808;
  padding: 15px 32px;
  gap: 20px;
  /* color: #d1d1d1; */
  margin: 2rem 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dashboard_sec_content .user_based_search .fst_sec {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap !important;
}

.dashboard_sec_content .heading {
  line-height: 30px;
}

.dashboard_sec_content .user_based_search .logo {
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.user_based_search .fst_sec .form-control {
  background-color: #1a1a1a !important;
  box-shadow: none !important;
  outline: none;
  color: var(--white) !important;
  border: none !important;
  height: 50px;
  /* width: 300px; */
  /* gap: 20px; */
  margin-right: 20px;
}

.user_based_search .fst_sec .form-control::placeholder {
  color: var(--white) !important;
}

.user_based_search .fst_sec .go_btn {
  border-radius: 7px;
  height: 50px;
}

.user_based_search .fst_sec .preview_id {
  color: var(--white);
  font-size: 18px !important;
  font-weight: 500;
}

/* Plan Particular */
.plan_particular_content .heading {
  color: #d1d1d1;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.plan_particular_content .heading .level {
  color: #707070;
  font-size: 23px;
}

.plan_level_slider {
  background-color: #090808;
  border-radius: 10px;
  padding: 80px 0px;
}

.plan_level_slider .level_details .fst_sec {
  background-color: #171717;
  border-radius: 10px;
  padding: 40px 50px;
}

.plan_level_slider .level_details .fst_sec .fst_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.plan_level_slider .level_details .snd_row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  /* margin: 50px 0px; */
  min-height: 200px;
  /* height: 200px; */
  position: relative;
  /* padding-top: 20px; */
}

.plan_level_slider .level_details .snd_row .inside_cnt {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.plan_level_slider .level_details .cmn_bg {
  background-color: #2f2f2f;
  color: var(--black);
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  display: inline-block;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.plan_level_slider .level_details .ylw_bg {
  background-color: var(--yellow) !important;
}

.plan_level_slider .level_details .red_bg {
  background-color: red !important;
  color: var(--white);
}

.direct_part .foot_partner_level_collec {
  /* border: 0.5px solid #707070; */
  border-radius: 10px;
  background-color: var(--grey1);
  padding: 18px 25px;
  display: flex;
  justify-content: space-around;
  gap: 20px;
  color: #d1d1d1;
  margin: 2rem 0px;
  flex-wrap: wrap;
}

.direct_part .foot_partner_level_collec .direct_partner {
  min-width: 25px;
  min-height: 25px;
  background-color: var(--yellow);
  display: inline-block;
  border-radius: 20px;
}

.plan_level_slider .level_details .fst_row span {
  color: var(--white);
  font-weight: 600;
  font-size: 18px;
}

.plan_level_slider .swiper-horizontal {
  position: relative !important;
}

.plan_level_slider .swiper-button-prev {
  width: 100px;
  height: 100px;
  left: 20%;
  top: 32%;
}

.plan_level_slider .swiper-button-prev::after {
  position: absolute !important;
  background-image: url("./Assets/plan_level_bg.svg");
  background-size: 100px 100px;
  background-repeat: no-repeat;
  /* content: ' ' !important; */
  width: 100%;
  height: 100%;
  color: transparent;
}

.plan_level_slider .swiper-button-next {
  width: 100px;
  height: 100px;
  right: 20%;
  top: 32%;
}

.plan_level_slider .swiper-button-next::after {
  position: absolute !important;
  background-image: url("./Assets/plan_level_bg.svg");
  background-size: 100px 100px;
  background-repeat: no-repeat;
  /* content: ' ' !important; */
  width: 100%;
  height: 100%;
  color: transparent;
}

.plan_particular_content .back_btn a {
  color: var(--white);
  text-decoration: none;
}

.plan_particular_content .back_btn a:hover {
  color: var(--yellow);
  text-decoration: underline;
}

.level_card .plan_custom_navi {
  color: var(--white) !important;
  text-decoration: none;
}

.plan_level_slider .swiper-button-next:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.plan_level_slider .swiper-button-prev:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.plan_level_slider .level_details .custom_link_nav {
  text-decoration: none;
  color: var(--black);
}

/* end */

.partner_total_profits {
  background: #2f2f2f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.partner_total_profits .header {
  color: var(--yellow);
  text-align: center;
}

.partner_total_profits .content {
  color: #d1d1d1;
  justify-content: center;
}

.plan_particular_content_one .plan_level_slider .swiper-button-prev {
  top: 41%;
}

.plan_particular_content_one .plan_level_slider .swiper-button-next {
  top: 41%;
}

.my_tooltip.tooltip .tooltip-inner {
  background-color: #2f2f2f !important;
  color: #d1d1d1 !important;
  border-radius: 10px !important;
}

.my_tooltip.tooltip.bs-tooltip-left .tooltip-arrow::before {
  border-left-color: #2f2f2f !important;
}

.my_tooltip.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #2f2f2f !important;
}

.my_tooltip.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #2f2f2f !important;
}

.tooltip_user_icon {
  width: 30px !important;
  height: 30px !important;
}

.tooltip_reload_icon {
  width: 30px !important;
  height: 30px !important;
}

/* universal */
.universal_tron_plans .heading {
  color: #d1d1d1;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.universal_tron_plans .back_btn a {
  color: var(--white);
  text-decoration: none;
}

.universal_tron_plans .back_btn a:hover {
  color: var(--yellow);
  text-decoration: underline;
}

.universal_tron_plans .heading .level {
  color: #707070;
}

.plan_level_missed_sec_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 73%;
  left: 50%;
}

.plan_level_missed_sec_bg_content {
  background-color: #f63e48;
  border-radius: 10px;
}

.plan_card_sec .plan_custom_navi .foot_sec {
  height: 30px;
  min-height: 30px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.missed_tron_level_sec_bg {
  background-color: #f63e48 !important;
}

.plan_particular_content .missed_part_details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 55%;
  left: 50%;
}

.plan_particular_content .missed_part_details .icons {
  border: 0.5px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.plan_particular_content_one .missed_tron_level_sec_bg {
  min-height: 405px !important;
}

.user_profile_info .user_profile {
  width: 100px;
  height: 100px;
  position: relative;
}

.user_profile_info .user_profile img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: transparent linear-gradient(180deg, #f7bd02 0%, #e23104 100%) 0% 0% no-repeat padding-box;
  /* padding: 1px; */
}

.user_profile_info .user_profile .userbadge_logo {
  position: absolute;
  width: 28px;
  height: 28px;
  right: 5px;
  top: -2px;
}

.user_profile_info .fst_sec {
  display: flex;
  gap: 20px;
  align-items: center;
}

.user_profile_info .fst_sec .id_details .user_id {
  color: var(--white);
  font-size: 21px;
  font-weight: 500;
}

.user_profile_info .fst_sec .id_details .hdn_code {
  color: #4f4f4f;
  font-size: 16px;
}

.user_profile_info .snd_sec {
  background: transparent linear-gradient(77deg, #322bf2 0%, #013e5a 100%) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 20px 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
  /* flex-wrap: wrap; */
  gap: 20px;
}

.user_profile_info .snd_sec .link {
  font-size: 20px;
  font-weight: 600;
}

.user_profile_info .snd_sec .copy_link_btn {
  color: var(--black);
  padding: 12px 20px;
  background-color: var(--white);
  border: 1px solid var(--white);
  outline: none;
  border: none;
  box-shadow: none;
  border-radius: 8px;
}

/* Recent Activity */

.recent_activity .content_sec {
  background-color: #090808;
  border-radius: 10px;
  padding: 45px 45px 60px 45px;
}

.recent_activity .content_sec .cnt_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  gap: 20px;
}

.recent_activity .user_detail .logo_sec {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recent_activity .cnt_details .new_user {
  background-color: #211a07;
}

.recent_activity .cnt_details .wallet_user {
  background-color: #0a1c1a;
}

.recent_activity .content_sec .time {
  color: #565656;
  font-size: 14px;
}

.recent_activity .content_sec .txt {
  font-size: 16px;
}

.recent_activity .content_sec .user_detail {
  display: flex;
  gap: 20px;
  align-items: center;
}

.recent_activity .content_sec .user_id_btn {
  /* color: var(--yellow); */
  padding: 10px 25px;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 8px;
}

.recent_activity .content_sec .new_user {
  background-color: #211a07 !important;
  color: var(--yellow) !important;
}

.recent_activity .content_sec .wallet_user {
  background-color: #0a1c1a !important;
  color: #16cabd !important;
}

.recent_activity .user_detail .logo_sec .new_user {
  width: 30px;
  height: 30px;
}

.recent_activity .user_detail .logo_sec .wallet_logo {
  width: 27px;
  height: 27px;
}

.recent_activity .content_sec .cnt_details {
  margin-bottom: 2.3rem;
}

/* .content_sec:last-child {
  margin-bottom: 0rem !important;
} */
.recent_activity .content_sec .cnt_details:last-child {
  margin-bottom: 0px !important;
}

.recent_activity .see_more_btn {
  background: #171717 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 17px;
  color: var(--white);
  border: none;
  box-shadow: none;
  outline: none;
  font-weight: 500;
  font-size: 17px;
}

/* Links */
.admin_link_section .snd_sec {
  background: transparent linear-gradient(77deg, #322bf2 0%, #013e5a 100%) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 20px 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
  /* flex-wrap: wrap; */
  gap: 20px;
}

.admin_link_section .snd_sec .link {
  font-size: 20px;
  font-weight: 600;
}

.admin_link_section .snd_sec .copy_link_btn {
  color: var(--black);
  padding: 12px 20px;
  background-color: var(--white);
  border: 1px solid var(--white);
  outline: none;
  border: none;
  box-shadow: none;
  border-radius: 8px;
}

/* Partners */
.partners_side_section .table_sec .table-responsive {
  border-radius: 12px;
  color: #d1d1d1 !important;
  /* background: #000000 0% 0% no-repeat padding-box !important; */
}

.partners_side_section .table_sec .table-responsive .t_head {
  background-color: #171717;
  color: #d1d1d1;
}

.partners_side_section .table_sec .table-responsive .t_body {
  background-color: #090808;
  box-shadow: rgb(0 0 0 / 4%) 0px -21px 10px !important;
  color: #d1d1d1;
}

.partners_side_section .table_sec .table-responsive .t_head th {
  border: none !important;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.partners_side_section .table_sec .table-responsive .t_body td {
  border: none !important;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.partners_side_section .table> :not(:first-child) {
  border-top: 0px !important;
}

.partners_side_section .table_sec .partner_id {
  background-color: #211a07;
  padding: 7px 15px;
  color: var(--yellow);
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 6px;
}
.partners_side_section .table_sec .partner_id.partner_id_green
{
  background-color: #12d90f85;
  color: #fff !important;


}

.partners_side_section .partner_id_td {
  min-width: 150px;
  width: 150px;
}

.partners_side_section .user_logo_type {
  width: 40px;
  height: 40px;
  min-width: 30px;
  min-height: 30px;
  /* padding: 1px; */
  margin: 0 auto !important;
}

.partners_side_section .user_logo_type img {
  background: transparent linear-gradient(180deg, #f7bd02 0%, #e23104 100%) 0% 0% no-repeat padding-box;
  border-radius: 50%;
  padding: 1px;
}

.partners_side_section .profits_th {
  /* min-width: 90px !important; */
}

.partner_id_btn {
  background-color: #211a07;
  padding: 7px 15px;
  color: var(--yellow);
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 6px;
}

/* Board Section */
.board_plan_sec .heading {
  color: #d1d1d1;
  font-size: 24px;
}

.board_plan_sec .heading .level {
  color: #707070;
  font-size: 23px;
}

.board_plan_sec .head_section {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  color: var(--yellow);
}

.board_plan_sec .head_section h6 {
  font-size: 25px;
  font-weight: 500;
}

.board_plan_sec .content_sec {
  background-color: #090808;
  border-radius: 12px;
  padding: 50px 60px;
}

.board_plan_sec .board_collection .heading_txt {
  color: var(--yellow);
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.board_plan_sec .plan_custom_navi .foot_sec {
  height: 30px;
  min-height: 30px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.board_plan_sec .body_sec {
  /* margin: 2rem 0px; */
  /* height: 170px; */
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.board_plan_sec .body_sec .list {
  /* gap: 30px; */
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
}

.board_plan_sec .body_sec .cmn_bg_color {
  width: 90px;
  display: inline-block;
  height: 90px;
  border-radius: 50%;
  background-color: #2f2f2f;
}

.board_plan_sec .body_sec .completed {
  background-color: var(--yellow) !important;
  font-size: 12px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: 600;
  cursor: pointer;
}

.board_plan_sec .level_card {
  border-radius: 15px !important;
}

.board_plan_sec .level_card .card-body {
  padding: 30px;
}

.board_plan_sec .level_card .plan_custom_navi {
  font-size: 25px;
}

.board_plan_sec .level_card .plan_custom_navi .foot_sec .user_icon {
  width: 45px;
  height: 45px;
}

.board_plan_sec .level_card .plan_custom_navi .foot_sec .reload_icon {
  width: 40px;
  height: 40px;
}

.board_plan_sec .coin_logo {
  width: 27px;
  height: 27px;
  object-fit: contain;
}

.board_plan_sec .owl-nav .owl-prev {
  background-image: url("./Assets/leftarrowBg.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  height: 60px;
  width: 60px;
}

.board_plan_sec .owl-nav .owl-prev:hover {
  background: url("./Assets/leftarrowBg.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  height: 60px;
  width: 60px;
}

.board_plan_sec .owl-nav .owl-prev span {
  visibility: hidden;
}

.board_plan_sec .owl-nav .owl-next {
  background-image: url("./Assets/rightarrowBg.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  height: 60px;
  width: 60px;
}

.board_plan_sec .owl-nav .owl-next:hover {
  background: url("./Assets/rightarrowBg.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  height: 60px;
  width: 60px;
}

.board_plan_sec .owl-nav .owl-next span {
  visibility: hidden;
}

.board_plan_sec .owl-theme .owl-nav {
  margin-top: 30px !important;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 15px;
}

/* .board_plan_sec .owl-theme .owl-nav [class*="owl-"]:hover {
  background: url(http://localhost:3000/static/media/leftarrowBg.434ebb7edf3ebfdca235.svg) !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  height: 60px;
  width: 60px;
} */

.board_plan_sec .border_sec {
  /* padding: 40px;
  border: 0.5px solid #2e2e2e;
  border-radius: 10px; */
}

.board_plan_sec .content_sec .board_collection {
  border: 0.5px solid #2e2e2e;
  border-radius: 12px;
  padding: 40px;
}

.header_sbar {
  background-color: transparent;
  flex: 1 1;
  border: none;
  outline: none;
  color: #fff;
  /* font-family: "Futura New Medium"; */
  /* padding-left: 5px; */

  width: 230px;
  height: 23px;
  padding: 5px !important;
  font-size: 14px;
}

.header_sbar:focus {
  color: white !important;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.header_sbar::placeholder {
  color: var(--white) !important;
}

.header_search {
  /* background-color: #28253f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 5px 10px;
  transition: all 2s ease; */

  border: 1px solid #707070;
  border-radius: 20px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  /* margin-left: 12px; */
  transition: all 2s ease;
}

.header_search i {
  color: #fff;

  cursor: pointer;
}

.header_search .search_icon {
  font-size: 22px;
  color: white;
}

.header_search .close_icon {
  /* width: 20px;
  height: 20px; */
  font-size: 26px;
  cursor: pointer;
  color: white;
}

.notification_btn .dropdown-menu[data-bs-popper] {
  right: 0px;
  left: unset !important;
}

.login_out_btn .dropdown-menu[data-bs-popper] {
  right: 0px !important;
  left: unset !important;
}

/* .board_plan_sec .locked_plan {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.board_plan_sec .locked_plan .plan_level {
  color: var(--white);
  font-size: 25px;
  font-weight: 500;
}
.board_plan_sec .lock_lottie {
  width: 300px;
  height: 300px;
}
.board_plan_sec .unlock_btn {
  padding: 10px 40px !important;
  border-radius: 10px;
} */

.common_level_card {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: start;
}

.common_level_card .level_card {
  /* width: 225px; */
  width: 250px;
  margin: unset !important;
}

.hidesidebar .sidelist {
  display: none;
}

.custom_prev_slide {
  background-image: url("./Assets/plan_level_bg.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #0000;
  height: 100px;
  position: absolute !important;
  width: 100px;
  left: 20%;
  top: 32%;
  cursor: pointer;
  z-index: 10000 !important;
}

.custom_prev_slide .content {
  color: #000;
  width: 100%;
  height: 100%;
  position: relative;
}

.custom_prev_slide .content span {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 25px;
  font-weight: 600;
}

.custom_next_slide {
  background-image: url("./Assets/plan_level_bg.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #0000;
  position: absolute !important;
  height: 100px;
  right: 20%;
  top: 32%;
  width: 100px;
  cursor: pointer;
  z-index: 10000 !important;
}

.custom_next_slide .content {
  color: #000;
  width: 100%;
  height: 100%;
  position: relative;
}

.custom_next_slide .content span {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 25px;
  font-weight: 600;
}

.plan_particular_content .swiper-button-disabled {
  opacity: 0.5;
}

.custom_parti_prev_slide {
  background-image: url("./Assets/plan_level_bg.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #0000;
  height: 100px;
  position: absolute !important;
  width: 100px;
  left: 9%;
  top: 32%;
  cursor: pointer;
  z-index: 10000 !important;
}

.custom_parti_prev_slide .content {
  color: #000;
  width: 100%;
  height: 100%;
  position: relative;
}

.custom_parti_prev_slide .content span {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 25px;
  font-weight: 600;
}

.custom_parti_next_slide {
  background-image: url("./Assets/plan_level_bg.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #0000;
  position: absolute !important;
  height: 100px;
  right: 9%;
  top: 32%;
  width: 100px;
  cursor: pointer;
  z-index: 10000 !important;
}

.custom_parti_next_slide .content {
  color: #000;
  width: 100%;
  height: 100%;
  position: relative;
}

.custom_parti_next_slide .content span {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 25px;
  font-weight: 600;
}

.plan_particular_content .swiper-button-disabled {
  opacity: 0.5;
}

.custom_search_drop .dropdown-menu {
  background: #171717;
  padding: 5px 10px;
  border-radius: 8px;
}

.custom_search_drop .dropdown-menu .list {
  background-color: transparent;
  border: none !important;
  color: var(--white);
  box-shadow: none !important;
  outline: none !important;
  display: flex;
  justify-content: start;
  cursor: pointer;
  padding: 9px 15px !important;
  font-size: 14px;
}

.custom_search_drop .dropdown-menu .list_items {
  max-height: 200px;
  height: 170px;
  overflow-y: auto;
  /* scrollbar-color: #007 #bada55 */
}

.custom_search_drop .dropdown-menu .list:hover {
  background-color: black;
  border-radius: 8px;
}

/* .custom_suggestion_drop_list {
  max-height: 250px;
  height: 250px;
  overflow-y: auto;
} */
.admin_header_sec .header_rgt_search_sec .custom_search_drop .dropdown-toggle {
  border: 1px solid #707070;
  border-radius: 20px;
  background-color: transparent !important;
  padding: 7px 9px;
  margin-left: 12px;
}

.custom_search_drop .dropdown-menu[data-bs-popper] {
  left: -150px;
  left: -250px;
}

.dropdown-menu {
  background-color: #171717;
  color: white !important;
}

.dropdown-item {
  background-color: transparent;
  color: white;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: black;
  color: white;
}

.notification_content_custom .inside_content_collec {
  width: 300px;
  padding: 5px 9px;
  height: 170px;
  overflow: auto;
}

.notification_content_custom .all_mark {
  color: var(--yellow);
}

.notification_content_custom .content_sec_noti p {
  font-size: 14px;
}

.notification_content_custom .all_notification {
  font-size: 16px !important;
  color: var(--yellow);
}

.tooltip_img_cursor {
  cursor: pointer;
}

.my_current_plan_tooltip.tooltip .tooltip-inner {
  background-color: #2f2f2f !important;
  color: white !important;
  border-radius: 5px !important;
}

.my_current_plan_tooltip.tooltip .tooltip-inner::before {
  border-color: red !important;
  background-color: red !important;
}

.my_current_plan_tooltip.tooltip .tooltip-inner::after {
  border-color: red !important;
  background-color: red !important;
}

/* .my_current_plan_tooltip.bs-tooltip-left .tooltip-arrow::before {
  border-left-color: #2f2f2f !important;
}
.my_current_plan_tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #2f2f2f !important;
}
.my_current_plan_tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #2f2f2f !important;
} */

.recent_activity .see_more_btn:hover {
  background-color: #282626;
  color: #a29797;
}

.user_profile_info .snd_sec .copy_link_btn:hover {
  background-color: #171717 !important;
  color: white;
}

.copy_link_icon {
  cursor: pointer;
}

.custom_contai_overflow {
  min-width: 100%;
}

.copy_link_btn:hover {
  background-color: #171717 !important;
  color: white !important;
}

/* .notification_content_custom
  .inside_content_collec
  .notification_content_custom
  .inside_content_collec::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #090808 !important;
} */

/* .notification_content_custom .inside_content_collec::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: white !important;
} */

/* .notification_content_custom .inside_content_collec::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #090808 !important;
} */

/* .custom_search_drop .dropdown-menu .list_items::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #090808 !important;
} */

/* .custom_search_drop .dropdown-menu .list_items::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #090808 !important;
}

.custom_search_drop .dropdown-menu .list_items::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #090808 !important;
} */

.custom_search_drop .dropdown-menu .list_items::-webkit-scrollbar-thumb {
  background: var(--yellow) !important;
}

::-webkit-scrollbar-thumb {
  background: var(--yellow) !important;
  border-radius: 3px;
}

::-webkit-scrollbar {
  width: 3px !important;
  height: 3px !important;
}

.notification_content_custom .all_mark:hover {
  color: white;
  text-decoration: underline;
}

.notification_content_custom .all_notification:hover {
  color: white;
  text-decoration: underline;
}

.content_sec_noti:hover {
  background-color: black;
  color: white;
  cursor: pointer;
  border-radius: 8px;
}

body {
  scrollbar-color: var(--yellow) #000 !important;
}

::-webkit-scrollbar-track {
  /* background: var(--yellow) !important; */
}

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: var(--yellow) !important;
} */

::-webkit-scrollbar-thumb:hover {
  background: #520 !important;
}

.formctrl_bg .form-control:disabled,
.formctrl_bg .form-control[readonly] {
  background: var(--light-gry-clr) !important;
}

.new_party_profit.partner_total_profits {
  margin-top: 30px;
  background-color: #171717;
}

.search_custom_btn {
  background-color: var(--themeclr) !important;
  border: 1px solid var(--themeclr) !important;
  color: #000;
  /* border: none !important; */
  border-radius: 20px;
  padding: 1px 5px;
  box-shadow: none;
}

.search_custom_btn:hover {
  background-color: transparent !important;
  border: 1px solid var(--themeclr) !important;
  color: var(--themeclr);
}

.custom_plan_particular_blur {
  position: absolute;
  /* top: 30%; */
  /* left: 48%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.skeleton_card_one .level {
  height: 30px;
  width: 80px;
  /* margin-bottom:6px; */
  border-radius: 6px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.skeleton_card_one .coin_detail {
  height: 30px;
  width: 80px;
  /* margin-bottom:6px; */
  border-radius: 6px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.skeleton_card_one .users {
  height: 30px;
  width: 80px;
  /* margin-bottom:6px; */
  border-radius: 6px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.skeleton_card_one .reload {
  height: 30px;
  width: 80px;
  /* margin-bottom:6px; */
  border-radius: 6px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.skeleton_card_one .circle {
  height: 40px;
  width: 40px;
  border-radius: 24px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  /* background: #4af702; */
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.skeletondash .total_cnts {
  height: 37.5px;
  width: 130px;
  /* margin-bottom:6px; */
  border-radius: 2px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.SkeletonLoading_Particular .level,
.SkeletonLoading_Particular .id,
.SkeletonLoading_Particular .coin {
  height: 35px;
  width: 110px;
  border-radius: 2px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.SkeletonLoading_Particular .circle {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.SkeletonLoading_Particular .profit_heading {
  height: 35px;
  min-width: 110px;
  border-radius: 2px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.SkeletonLoading_Particular .profit_value {
  height: 35px;
  width: 50px;
  border-radius: 2px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.SkeletonLoading_Particular .custom_prev_slide {
  background-image: none !important;
}

.SkeletonLoading_Particular .custom_prev_slide .content {
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
  border-radius: 50%;
}

.SkeletonLoading_Particular .custom_next_slide {
  background-image: none !important;
}

.SkeletonLoading_Particular .custom_next_slide .content {
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
  border-radius: 50%;
}

.userdash_skeleton .circle {
  width: 38px;
  height: 38px;
  border-radius: 24px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.userdash_skeleton .btn_sec_loader {
  width: 112px;
  height: 40px;
  border-radius: 10px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.userdash_skeleton .img_logo {
  width: 60px;
  height: 60px;
  border-radius: 3px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.Skeleton_boardplan .level,
.Skeleton_boardplan .coin,
.Skeleton_boardplan .user,
.Skeleton_boardplan .reload {
  height: 35px;
  width: 110px;
  border-radius: 8px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.Skeleton_boardplan .circle {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.Skeleton_boardplan .circle {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.Skeleton_boardplan .profit_heading {
  height: 35px;
  min-width: 150px;
  border-radius: 8px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.Skeleton_boardplan .profit_value {
  height: 35px;
  width: 50px;
  border-radius: 8px;
  background: rgba(130, 130, 130, 0.2);
  background: linear-gradient(to right,
      rgba(130, 130, 130, 0.2) 8%,
      rgba(130, 130, 130, 0.3) 18%,
      rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

@keyframes wave-lines {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

/* @keyframes wave-squares {
  0% {
      background-position: -468px 0;

  }
   100% {
      background-position: 468px 0;

  }
} */

.custom_toast_theme {
  background-color: #171717 !important;
  color: white !important;
}

.board_plan_custom_ht {
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0px;
  /* min-height: 150px !important; */
}

.board_plan_custom_parti .level_card {
  width: 280px !important;
  min-height: 230px;
}

.data_not_found_txt {
  text-align: center;
  margin: 15px 0px;
  color: #d1d1d1;
  font-weight: 600;
}

.custom_dash_profits {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom_dash_profits button {
  border-radius: 7px !important;
  padding: 3px 16px !important;
}

.custom_missed_details {
  /* background-color: red; */
  position: relative;
  display: flex;
  /* top: 78px; */
  /* min-width: 200px; */
  padding: 2px 10px;
}

.custom_card_bg_clr {
  /* p-3 */
  padding: 0px;
}

.custom_card_bg {
  padding: 15px;
}

.blur_card_secc {
  /* background-color: rgba(225, 68, 77, 1);
  border-radius: 8px; */
}

.plan_card_sec .cus_bg_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  /* transform: translate(-50%, -50%); */
  top: 0;
  /* left: 50%; */

  /* width: 218px; */
  /* height: 214px;  */
  width: 100%;
  height: 100%;
  background-color: rgba(225, 68, 77, 1);
  border-radius: 8px;
}

.custom_universal_plan_bg_clr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  /* transform: translate(-50%, -50%); */
  top: 0;
  /* left: 50%; */
  /* width: 387px; */
  /* height: 321px; */
  width: 100%;
  height: 100%;
  background-color: rgba(225, 68, 77, 1);
  border-radius: 8px;
}

/* rgba(225,68,77,1) */

.card_bg_red {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  position: absolute;
  /* transform: translate(-50%, -50%); */
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(225, 68, 77, 1);
  border-radius: 10px;
  /* left: 50%;*/
}

/* card bg red start*/
.board_plan_card_bg {
  background-color: rgba(225, 68, 77, 1) !important;
  border-radius: 12px;
  position: relative;
}

.board_plan_card_bg .level_card {
  visibility: hidden;
}

.board_plan_card_bg .card_missed {
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

/* card bg red end*/

/*locked sec start*/
.board_plan_card_lock {
  border-radius: 12px;
  position: relative;
}

.board_plan_card_lock .level_card {
  background-color: var(--card-header);
  filter: blur(10px);
}

.board_plan_card_lock .lock_sec {
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

/*locked sec end*/

/* .blur_card_secc{
  background-color: rgba(225, 68, 77, 1) !important;
} */
.dash_profit_heading {}

.withdraw_custom_input {
  background-color: #1a1a1a !important;
  box-shadow: none !important;
  outline: none;
  color: var(--white) !important;
  border: none !important;
  height: 40px;
  padding-left: 10px;
}

.custom_dash_profits .sec_snd {
  /* width: 2px;
  background: #171717;
  min-height: 150px; */
  border-right: 1px solid #171717;
  left: 50%;
  position: absolute;
  height: 100%;
}

.custom_dash_profits .sec_fst {
  padding: 21px 24px;
}

.custom_dash_profits .sec_trd {
  padding: 21px 24px;
}

.dash_profit_owl {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.content_sec .custom_slider .slick-prev {
  /* left: 0px !important; */
}

.content_sec .custom_slider .slick-next {
  /* right: 0px !important; */
}

.dash_profit_slider .custom_slider .slick-prev {
  left: 0px !important;
}

.dash_profit_slider .custom_slider .slick-next {
  right: 0px !important;
}

.custom_slider .slick-prev:before,
.custom_slider .slick-next:before {
  /* color: #171717 !important; */
}

.plan_particular_content .custom_slider {
  width: 100% !important;
}

.plan_particular_content .custom_slider .slick-prev {
  left: 0px !important;
}

.plan_particular_content .custom_slider .slick-next {
  right: 0px !important;
}

.content_sec .custom_slider .slick-list {
  margin: 0px 20px;
}

.plan_particular_content .custom_slider .slick-list {
  margin: 0px 20px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .plan_level_slider .level_details .fst_sec {
    padding: 30px 35px !important;
  }
}

.cus_plan_tron {
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
}

.plan_trans_custom_tabs {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #70707033;
}

.plan_trans_custom_tabs .active_btn {
  background-color: #171717;
  color: #d1d1d1;
  border: 1px solid transparent !important;
}

.plan_trans_custom_tabs .inactive_btn {
  background-color: transparent;
  /* border-bottom: 2px solid ; */
  color: #d1d1d1;
  border: 1px solid transparent;
  /* border: none !important; */
  box-shadow: none !important;
}

.plan_trans_custom_tabs button {
  outline: none !important;
  box-shadow: none !important;
  padding: 10px 35px;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.plan_trans_custom_tabs button:hover {
  background-color: #171717 !important;
}

/* notification list */

.noti-content {
  background-color: #171717;
}

.noti-content .login_success_txt {
  color: var(--white);
}

.noti-content .mark_read_btn {
  text-decoration: none;
  color: var(--yellow);
  text-align: end;
}

.noti-content .mark_read_btn:hover {
  color: var(--white);
  text-decoration: underline;
}

.noti-content_marked .mark_read_btn {
  text-decoration: none;
  color: var(--yellow);
  text-align: end;
}

.noti-content_marked .mark_read_btn:hover {
  color: var(--white);
  text-decoration: underline;
}

.noti-content .date_time {
  /* color: var(--lite-grey); */
  color: #dcdcdc;
  font-size: 14px;
}

.noti-content .side_bg {
  width: 6px;
  background-color: var(--yellow);
}

.noti-content_marked {
  /* background-color: #9d9d9d; */
  background-color: #090808;
  opacity: 0.4;
}

.noti-content_marked .login_success_txt {
  color: #ccc;
}

.noti-content_marked .date_time {
  color: #ccc;
  font-size: 14px;
}

.noti-content_marked .side_bg {
  width: 6px;
  /* background-color: #605a5a; */
  background-color: var(--yellow);
}

/* .noti-content_marked .mark_as_read {
  color: #ccc;
} */

/* .noti-content_marked .mark_us_read_icon {
  color: #ccc;
} */
.mark_all_read {
  color: var(--yellow);
  text-decoration: none;
}

.mark_all_read:hover {
  color: var(--white);
  text-decoration: underline;
}

@media (max-width: 380px) {
  .notifications .login_success_txt {
    font-size: 13px;
  }

  .notifications .date_time {
    font-size: 13px;
  }

  .notifications .mark_as_read {
    font-size: 13px;
  }
}

/* sidebar dropdown */
.custom_transaction_drop .dropdown-toggle {
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  border-radius: 0px 50px 50px 0px;
  border-left: 2px solid transparent;
  background-color: transparent !important;
  border-color: transparent !important;
  text-decoration: none;
  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 11px;
  padding-bottom: 12px;
  padding-left: 15px;
}

.custom_transaction_drop .dropdown-toggle:hover {
  background: #171717 !important;
  position: relative;
  border-left: 2px solid var(--themeclr) !important;
}

.custom_transaction_drop .dropdown-menu.show {
  width: 100% !important;

  transform: translate(0px, 48px) !important;
  inset: 0px auto auto 0px !important;
}

.custom_transaction_drop .dropdown-menu {
  /* background-color: #090808; */
  background-color: transparent;
  padding-top: 3px;
}

.custom_transaction_drop .custom_url {
  padding-left: 49px !important;
  margin-bottom: 5px;
}

.custom_transaction_drop .active-item {
  background: #171717;
  position: relative;
  border-left: 2px solid var(--themeclr) !important;
}

.notification_btn {
  position: relative;
}

.notification_btn .noti_val {
  width: 15px;
  height: 15px;
  background-color: red;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 9px;
  position: absolute;
  right: 0px;
  top: -5px;
  padding-right: 1px;
}

/* .sidebar .sidelist */

/* .sidebar::-webkit-scrollbar {
  width: 2px !important;
}
.sidebar::-webkit-scrollbar-thumb {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: red !important; 
} */

.action_edit_btn {
  padding: 4px 8px !important;
  border-radius: 5px !important;
  font-size: 15px !important;
}

.partners_side_section .modal-footer {
  border-top: none !important;
}

.partners_side_section .modal-body {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.total_partner_cnt {
  background-color: #211a07 !important;
  padding: 4px 13px;
  color: var(--yellow) !important;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 6px;
  font-size: 15px;
}

.board_plan_x3_single {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* align-items: center; */
  height: 100%;
}

.board_plan_x3_single .body_sec {
  display: unset !important;
  min-height: unset !important;
}

.board_plan_x3_single .body_sec .slick-slide>div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.board_plan_x3_single .slick-prev {
  left: -12px;
}

.board_plan_x3_single .slick-next {
  right: -12px;
}

.board_plan_x3_single .body_sec .cmn_bg_color {
  width: 40px !important;
}

.board_plan_x3_single_particular {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* align-items: center; */
  height: 100%;
}

.board_plan_x3_single_particular .body_sec .cmn_bg_color {
  width: 40px !important;
}

.board_plan_x3_single_particular .body_sec {
  display: unset !important;
  min-height: unset !important;
}

.board_plan_x3_single_particular .body_sec .slick-slide>div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.board_plan_x3_single_particular .slick-prev {
  left: -12px;
}

.board_plan_x3_single_particular .slick-next {
  right: -12px;
}

.dash_plan_fst_level {
  display: flex;
  gap: 20px;
  align-items: center;
}

.custom_board_plan_parti_slider {
  display: unset !important;
}

.custom_board_plan_parti_slider .list {
  display: unset !important;
}

.custom_board_plan_parti_slider .cmn_bg_color {
  width: 90px !important;
}

.custom_board_plan_parti_slider .slick-list {
  max-width: 300px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.custom_board_plan_parti_slider .custom_slider_sec {
  padding: 50px 0px;
}

.custom_board_plan_parti_slider .slick-slide>div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom_board_plan_parti_slider .slick-prev {
  left: 0px !important;
}

.custom_board_plan_parti_slider .slick-next {
  right: 0px !important;
}
@media (max-width: 380px) {
  .board_plan_sec .body_sec .cmn_bg_color {
    width: 40px !important;
    height: 40px !important;
  }
  .board_plan_sec .body_sec .completed{
    font-size: 10px !important;
  }
  .plan_particular_content .custom_slider .slick-next {
    right: -13px !important;
  }
  .plan_particular_content .custom_slider .slick-prev {
    left: -13px !important;
  }
}

@media (min-width:381px) and (max-width:480px){
  .board_plan_sec .body_sec .cmn_bg_color {
    width: 45px !important;
    height: 45px !important;
  }
  .board_plan_sec .body_sec .completed{
    font-size: 10px !important;
  }
  .plan_particular_content .custom_slider .slick-next {
    right: -13px !important;
  }
  .plan_particular_content .custom_slider .slick-prev {
    left: -13px !important;
  }
}

@media (max-width: 480px) {
  .custom_dash_profits .sec_fst {
    padding: 8px !important;
  }

  .board_plan_x3_single_particular .slick-prev {
    left: -15px !important;
  }

  .board_plan_x3_single_particular .slick-next {
    right: -15px !important;
  }

  .custom_board_plan_parti_slider .slick-prev {
    left: -13px !important;
  }

  .custom_board_plan_parti_slider .slick-next {
    right: -13px !important;
  }

  /* .custom_board_plan_parti_slider_lvl1{
    min-height: 80px !important;
  } */
  .partners_side_section .table_sec .partner_id {
    padding: 5px 11px !important;
    font-size: 12px !important;
  }

  .partners_side_section .table_sec .table-responsive .t_body td {
    font-size: 13px !important;
  }

  .partners_side_section .table_sec .table-responsive .t_head th {
    font-size: 14px !important;
  }

  .custom_a_wht {
    font-size: 13px !important;
  }
  .dash_personal_link {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-width: 575px) {
  .header_nav {
    display: flex;
    gap: 20px;
    margin-top: 15px;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media(max-width:1440px) {
  .custom_board_plan_parti_slider .custom_slider_sec {
    padding: 68px 0px;
  }
}

.red_err_txt {
  color: red;
  cursor: pointer;
}

.mobileadmin.menus .custom_transaction_drop .dropdown-menu.show {
  transform: translate(0px, 0px) !important;
}
