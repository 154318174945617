@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

:root {
  --textwhtclr: #fff;
  --textlightclr: #dcdcdc;
  --textblkclr: #000;
  --themeclr: #f7bd02;
  --yellow: #f7bd02;
  --white: #fff;
  --black: #000;
  --card-header: #171717;
  --grey1: #171717;
  --card-body: #090808;

  --green-clr: #23ff43;
  --red-clr: #ad1e1c;
  --gray-clr: #8c8c8e;
  --blue-clr: #406aff;
  --light-gry-clr: #31343d;
}
body {
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #000;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
body::-webkit-scrollbar {
  width: 9px;
  background-color: #f5f5f5;
}
body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f7bd02;
}
table {
  /* table-layout: fixed; */
}
.sidecontent {
  padding-left: 5%;
}
.sidecontent span {
  font-size: 40px;
  color: #f7bd02;
}
.card .getstart {
  padding: 7px 14px;
  width: 160px;
  font-size: 15px;
}
.card .getstart:after {
  top: 3px;
}
.f-12 {
  font-size: 12px !important;
}
.f-14 {
  font-size: 14px;
}
.f-15 {
  font-size: 15px;
}
.textclr {
  color: var(--textwhtclr);
}
.themeclr {
  color: var(--themeclr);
}
.lighttxtclr {
  color: var(--textlightclr);
  font-size: 14px;
}
.overall {
  padding-top: 92px;
}
.allheader .navbar {
  background: #000000f2;
}
.headerlogo {
  width: 50px;
  min-width: 50px;
}
.register {
  background-color: var(--themeclr);
  border: 1px solid var(--themeclr);
  border-radius: 8px;
  color: #000;
  font-weight: 500;
}
.register:hover {
  background-color: transparent;
  border: 1px solid var(--themeclr);
  color: var(--themeclr);
}
.register:focus {
  background-color: var(--themeclr);
  border: 1px solid var(--themeclr);
  box-shadow: unset;
  color: #000;
}
.channel {
  text-align: end;
}
.register:active:focus {
  box-shadow: unset;
}
.bannersection,
.business_plan {
  background-color: var(--textblkclr);
  padding: 2% 0% 3% 0%;
}
.business_plan {
  padding: 4% 0% 4% 0%;
}
.bannerinside {
  background: url("./Assets/map_bg.png") no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding: 2% 0%;
}

.getstart:hover {
  background-color: var(--themeclr) !important;
  border: 1px solid var(--themeclr);
  color: var(--textblkclr) !important;
}
.getstart {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  padding: 9px 16px;
  font-weight: 600 !important;
  width: 180px;
}
.getstart:after {
  content: "\2192";
  position: absolute;
  opacity: 0;
  top: 5px;
  right: -20px;
  transition: 0.5s;
  font-size: 20px;
  font-weight: 600;
}
.getstart:hover {
  padding-right: 40px;
  padding-left: 8px;
}
.getstart:hover:after {
  opacity: 1;
  right: 10px;
}
.partnermarquee {
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.partnermarquee .partnerwidth:nth-child(1) {
  margin-left: 3%;
}
.partnerwidth {
  width: 320px;
  border: 1px solid #191919;
  background-color: #060606;
  border-radius: 16px;
  padding: 16px 16px;
}
.marqueeimg {
  width: 50px;
  height: 50px;
}
.lightgray {
  color: gray;
  font-size: 12px;
}
.greenclr {
  color: #15c9bc;
}
.footer {
  background-color: #1111118f !important;
}
.choosesection {
  background-color: #030303;
  padding: 4% 0%;
}
.subtitle {
  width: 100%;
  max-width: 65%;
  min-width: 65%;
  margin: auto;
  color: var(--textlightclr);
  font-size: 13px;
}
.chooseborder {
  border: 1px solid #111111;
  background-color: #111111;
  padding: 16px;
  border-radius: 14px;
  transition: 0.5s;
}
.card {
  background-color: #111111;
  border-radius: 10px;
  /* width: 90%; */
  margin: auto;
}
.cardimg {
  border: 1px solid #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  width: 78px;
  height: 78px;
  margin: auto;
}

.card img {
  /* width: 56px; */
  /* height: 56px; */
  /* object-fit: contain; */
}
.security {
  width: 18% !important;
  margin: auto;
}
.footer input {
  font-size: 13px;
  background-color: #000;
  color: #fff;
  border: 0.1px solid #70707033;
  border-radius: 6px;
}
.footer input:focus {
  background-color: #000;
  border: 1px solid #7070704d;
  box-shadow: unset;
  color: #fff;
  box-shadow: unset;
}
.footer a {
  display: flex;
  height: 30px;
  width: 30px;
  border: 1px solid var(--themeclr);
  background-color: var(--themeclr);
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  border-radius: 6px;
  transition: 0.5s;
}
.footer a svg {
  color: #000;
}
.footer a:hover {
  background-color: var(--textblkclr);
  transition: 0.5s;
}
.footer a:hover svg {
  color: var(--themeclr);
}
.planimg {
  position: relative;
  z-index: 6;
}
.arrows {
  position: absolute;
  max-width: 14%;
  right: 45%;
  top: 5%;
}

/* modal */
.modal-header {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  border-bottom: unset !important;
}
.modal-content {
  background-color: #060606 !important;
  border-radius: 14px !important;
  border: 0.4px solid #7070704d;
}
.btn-close {
  opacity: unset !important;
  background-color: var(--themeclr) !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  width: 9px !important;
  height: 9px !important;
  position: absolute;
  right: 0px;
  top: -5px;
}
.walletmodal h5 {
  font-size: 20px;
  color: var(--textlightclr);
}
.walletmodal .walletborder {
  border: 1px solid #000;
  padding: 12px;
  border-radius: 8px;
  background-color: #000;
  cursor: pointer;
}
.walletborder span {
  color: var(--textlightclr);
  font-size: 15px;
}
.walletmodal .walletborder:hover {
  border: 1px solid var(--themeclr);
  color: var(--textblkclr);
  background-color: var(--themeclr);
}
.walletmodal .walletborder:hover span {
  color: var(--textblkclr);
}
.walletmodal .modal-body {
  padding-left: 8%;
  padding-right: 8%;
}
.tick {
  width: 60%;
  max-width: 60%;
  min-width: 60%;
  margin: auto;
  margin-top: -15%;
}
.mtop {
  margin-top: -5%;
}
.mar_style {
  text-align: justify;
  margin: auto;
  width: 55% !important;
}
.modal-backdrop.show {
  /* opacity: 1 !important; */
  background-color: #000000f7 !important;
}
.pos-rel {
  position: relative;
  z-index: 6;
}
.coinani {
  position: absolute;
  width: 21%;
  max-width: 21%;
  min-width: 21%;
  z-index: -1;
}
.commision {
  border: 1px solid #f7bd0299;
  background: #111111;
  box-shadow: 0px 0px 8px #f7bd02b8;
  border-radius: 16px;
  padding: 20px 10px;
  width: 100%;
  min-width: 30%;
  max-width: 30%;
  position: absolute;
}
.income2 {
  display: none;
}
.income1 {
  display: block;
}
.arrowicon {
  position: absolute;
  width: 8%;
  max-width: 8%;
  min-width: 8%;
}

@media only screen and (min-width: 1400px) {
  .coinani {
    right: 24%;
    top: 30%;
  }
  .commision {
    right: 18%;
    top: 20%;
  }
  .arrowicon {
    bottom: 18%;
    left: 12%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .coinani {
    right: 14%;
    top: 35%;
  }
  .commision {
    right: 8%;
    top: 25%;
  }
  .arrowicon {
    bottom: 18%;
    left: 15%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .coinani {
    right: 0;
    top: 40%;
  }
  .commision {
    right: 0%;
    top: 25%;
  }
  .arrowicon {
    bottom: 20%;
    left: 20%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card {
    width: 100%;
  }
  .coinani {
    right: -8%;
    top: 42%;
  }
  .commision {
    right: 0%;
    top: 25%;
    min-width: 40%;
    max-width: 40%;
  }
  .arrowicon {
    bottom: 20%;
    left: 20%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .coinani {
    right: -8%;
    top: 42%;
  }
  .commision {
    right: 0%;
    top: 12%;
    min-width: 48%;
    max-width: 48%;
  }
  .arrowicon {
    bottom: 20%;
    left: 20%;
  }
}

@media only screen and (min-width: 576px) {
  .walletmodal .modal-dialog {
    max-width: 420px;
  }
}

@media only screen and (max-width: 575px) {
  .arrowicon {
    bottom: 24%;
    left: 30%;
  }
  .btn-close {
    right: 5px;
    top: 2px;
  }
  .income2 {
    display: block;
  }
  .income1 {
    display: none;
  }
  .business_plan {
    padding: 12% 0% 12% 0%;
  }
  .security {
    width: 50% !important;
  }
  .earned {
    font-size: 10px !important;
  }
  .earnedno {
    font-size: 20px !important;
    margin-top: 5px;
  }
  .coinani {
    right: 2%;
    top: 45%;
  }
  .commision {
    padding: 10px 10px;
    min-width: 35%;
    max-width: 35%;
    right: 8px;
    top: 22%;
  }
  .choosesection {
    padding: 8% 0%;
  }
  .channel {
    text-align: center;
  }
  .subtitle {
    max-width: 100%;
    min-width: 100%;
  }
  .bannerinside {
    padding: 2% 0% 11% 0%;
  }
  .sidecontent {
    padding-left: 5%;
  }
  .footer .row {
    text-align: center;
  }
  .footer .justify-content-end {
    justify-content: center !important;
  }
  .mar_style {
    width: 90% !important;
  }
}

/* @media (max-width: 380px) {
  .user_based_search .fst_sec .form-control {
    width: 130px !important;
  }
}
@media (min-width: 381px) and (max-width: 575px) {
  .user_based_search .fst_sec .form-control {
    width: 130px !important;
  }
} */

@media (min-width: 381px) and (max-width: 767px) {
  .dashboard_sec_content .user_based_search {
    flex-wrap: unset !important;
    align-items: end !important;
  }
}

@media (max-width: 576px) {
  .card_section .cmn_card .total_count {
    font-size: 20px !important;
  }
  .board_plan_sec .heading .level {
    font-size: 15px !important;
  }
  .board_plan_sec .heading {
    font-size: 15px !important;
  }
  .board_plan_sec .head_section h6 {
    font-size: 16px !important;
  }
}

@media (min-width: 1441px) {
  .plan_particular_content_one .plan_level_slider .swiper-button-prev {
    left: 10%;
  }
  .plan_particular_content_one .plan_level_slider .swiper-button-next {
    right: 10%;
  }
}

@media (min-width: 1201px) and (max-width: 1440px) {
  .plan_level_slider .swiper-button-prev {
    width: 80px !important;
    height: 80px !important;
    left: 18%;
    top: 32%;
  }
  .plan_level_slider .swiper-button-prev::after {
    background-size: 80px 80px !important;
  }

  .plan_level_slider .swiper-button-next {
    width: 80px !important;
    height: 80px !important;
    right: 18%;
    top: 32%;
  }
  .plan_level_slider .swiper-button-next::after {
    background-size: 80px 80px !important;
  }

  .plan_particular_content_one .plan_level_slider .swiper-button-prev {
    left: 10%;
  }
  .plan_particular_content_one .plan_level_slider .swiper-button-next {
    right: 10%;
  }

  .common_level_card .level_card {
    width: 220px !important;
  }

  .custom_next_slide {
    right: 2% !important;
  }
  .custom_prev_slide {
    width: 85px !important;
    height: 85px !important;
    left: 110px !important;
  }
  .custom_prev_slide .content span {
    font-size: 17px !important;
  }
  .custom_next_slide {
    width: 85px !important;
    height: 85px !important;
    right: 110px !important;
  }
  .custom_next_slide .content span {
    font-size: 17px !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .plan_level_slider .swiper-button-prev {
    width: 60px !important;
    height: 60px !important;
    left: 7% !important;
    top: 32%;
  }
  .plan_level_slider .swiper-button-prev::after {
    background-size: 60px 60px !important;
  }

  .plan_level_slider .swiper-button-next {
    width: 60px !important;
    height: 60px !important;
    right: 7% !important;
    top: 32%;
  }
  .plan_level_slider .swiper-button-next::after {
    background-size: 60px 60px !important;
  }
  .card_section .cmn_card .total_count {
    font-size: 18px !important;
  }
  .user_profile_info .snd_sec {
    padding: 12px 20px !important;
  }
  .user_profile_info .snd_sec .link {
    font-size: 16px !important;
  }
  .user_profile_info .snd_sec .personal_link_txt {
    font-size: 15px;
  }
  .user_profile_info .snd_sec .copy_link_btn {
    padding: 10px 15px !important;
    font-size: 15px !important;
  }

  .custom_next_slide {
    right: 2% !important;
  }
  .custom_prev_slide {
    width: 70px !important;
    height: 70px !important;
    left: 20px !important;
    top: 40% !important;
  }
  .custom_prev_slide .content span {
    font-size: 17px !important;
  }
  .custom_next_slide {
    width: 70px !important;
    height: 70px !important;
    right: 20px !important;
    top: 40% !important;
  }
  .custom_next_slide .content span {
    font-size: 17px !important;
  }

  .custom_parti_prev_slide {
    width: 60px !important;
    height: 60px !important;
    left: 2% !important;
    top: 43% !important;
  }

  .custom_parti_next_slide {
    width: 60px !important;
    height: 60px !important;
    right: 2% !important;
    top: 43% !important;
  }
  .custom_parti_prev_slide .content span {
    font-size: 15px !important;
  }
  .custom_parti_next_slide .content span {
    font-size: 15px !important;
  }
  .custom_search_drop .dropdown-menu[data-bs-popper] {
    left: -150px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .plan_level_slider .swiper-button-prev {
    width: 60px !important;
    height: 60px !important;
    left: 13% !important;
    top: 32%;
  }
  .plan_level_slider .swiper-button-prev::after {
    background-size: 60px 60px !important;
  }

  .plan_level_slider .swiper-button-next {
    width: 60px !important;
    height: 60px !important;
    right: 13% !important;
    top: 32%;
  }

  .plan_level_slider .swiper-button-next::after {
    background-size: 60px 60px !important;
  }
  .user_profile_info .snd_sec {
    padding: 15px 20px !important;
  }
  .user_profile_info .snd_sec .copy_link_btn {
    padding: 10px 10px !important;
    font-size: 15px !important;
  }
  .user_profile_info .snd_sec .link {
    font-size: 19px !important;
  }
  /* 
  .board_plan_sec .content_sec {
    padding: 26px 33px !important;
  }
  .board_plan_sec .content_sec .board_collection {
    padding: 25px !important;
  }
  .board_plan_sec .level_card .card-body {
    padding: 15px !important;
  }
  .board_plan_sec .level_card .plan_custom_navi {
    font-size: 20px !important;
  }
  .board_plan_sec .coin_logo {
    width: 20px !important;
    height: 20px !important;
  }
  .board_plan_sec .level_card .plan_custom_navi .foot_sec .reload_icon {
    width: 32px !important;
    height: 32px !important;
  }
  .board_plan_sec .level_card .plan_custom_navi .foot_sec .user_icon {
    width: 35px !important;
    height: 35px !important;
  }
  .board_plan_sec .body_sec .cmn_bg_color {
    width: 50px !important;
    height: 50px !important;
  }
  .board_plan_sec .body_sec .list {
    gap: 19px !important;
  } */
  .card_section .cmn_card .total_count {
    font-size: 18px !important;
  }

  .custom_next_slide {
    right: 2% !important;
  }
  .custom_prev_slide {
    width: 70px !important;
    height: 70px !important;
    left: 20px !important;
    top: 40% !important;
  }
  .custom_prev_slide .content span {
    font-size: 17px !important;
  }
  .custom_next_slide {
    width: 70px !important;
    height: 70px !important;
    right: 20px !important;
    top: 40% !important;
  }
  .custom_next_slide .content span {
    font-size: 17px !important;
  }

  .custom_parti_prev_slide {
    width: 60px !important;
    height: 60px !important;
    left: 2% !important;
    top: 43% !important;
  }

  .custom_parti_next_slide {
    width: 60px !important;
    height: 60px !important;
    right: 2% !important;
    top: 43% !important;
  }
  .custom_parti_prev_slide .content span {
    font-size: 15px !important;
  }
  .custom_parti_next_slide .content span {
    font-size: 15px !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .plan_level_slider .swiper-button-prev {
    width: 60px !important;
    height: 60px !important;
    left: 3% !important;
    top: 32%;
  }
  .plan_level_slider .swiper-button-prev::after {
    background-size: 60px 60px !important;
  }

  .plan_level_slider .swiper-button-next {
    width: 60px !important;
    height: 60px !important;
    right: 3% !important;
    top: 32%;
  }
  .plan_level_slider .swiper-button-next::after {
    background-size: 60px 60px !important;
  }

  .board_plan_sec .content_sec {
    padding: 26px 33px !important;
  }
  .board_plan_sec .content_sec .board_collection {
    padding: 25px !important;
  }
  .board_plan_sec .level_card .card-body {
    padding: 15px !important;
  }
  .board_plan_sec .level_card .plan_custom_navi {
    font-size: 20px !important;
  }
  .board_plan_sec .coin_logo {
    width: 20px !important;
    height: 20px !important;
  }
  .board_plan_sec .level_card .plan_custom_navi .foot_sec .reload_icon {
    width: 32px !important;
    height: 32px !important;
  }
  .board_plan_sec .level_card .plan_custom_navi .foot_sec .user_icon {
    width: 35px !important;
    height: 35px !important;
  }
  .board_plan_sec .body_sec .cmn_bg_color {
    width: 50px !important;
    height: 50px !important;
  }
  .board_plan_sec .body_sec .list {
    gap: 19px !important;
  }
  .plan_level_slider .level_details .fst_sec {
    padding: 30px 35px !important;
  }

  .custom_next_slide {
    right: 2% !important;
  }
  .custom_prev_slide {
    width: 45px !important;
    height: 45px !important;
    left: 20px !important;
    top: 45% !important;
  }
  .custom_prev_slide .content span {
    font-size: 17px !important;
  }
  .custom_next_slide {
    width: 45px !important;
    height: 45px !important;
    right: 20px !important;
    top: 44% !important;
  }
  .custom_next_slide .content span {
    font-size: 17px !important;
  }
  .plan_level_slider .level_details .snd_row .inside_cnt {
    flex-wrap: unset !important;
  }
  .plan_level_slider .level_details .cmn_bg {
    width: 75px !important;
    height: 75px !important;
    min-width: 75px !important;
    min-height: 75px !important;
    font-size: 14px !important;
  }
  .custom_parti_prev_slide {
    width: 40px !important;
    height: 40px !important;
    left: 2% !important;
    top: 43% !important;
  }

  .custom_parti_next_slide {
    width: 40px !important;
    height: 40px !important;
    right: 2% !important;
    top: 43% !important;
  }
  .custom_parti_prev_slide .content span {
    font-size: 15px !important;
  }
  .custom_parti_next_slide .content span {
    font-size: 15px !important;
  }
}

@media (min-width: 481px) and (max-width: 575px) {
  .plan_level_slider .swiper-button-prev {
    width: 50px !important;
    height: 50px !important;
    left: 0% !important;
    top: 32%;
  }
  .plan_level_slider .swiper-button-prev::after {
    background-size: 50px 50px !important;
  }

  .plan_level_slider .swiper-button-next {
    width: 50px !important;
    height: 50px !important;
    right: 0% !important;
    top: 32%;
  }
  .plan_level_slider .swiper-button-next::after {
    background-size: 50px 50px !important;
  }

  .custom_next_slide {
    right: 2% !important;
  }
  .custom_prev_slide {
    width: 45px !important;
    height: 45px !important;
    left: 0px !important;
  }
  .custom_prev_slide .content span {
    font-size: 17px !important;
  }
  .custom_next_slide {
    width: 45px !important;
    height: 45px !important;
    right: 0px !important;
  }
  .custom_next_slide .content span {
    font-size: 17px !important;
  }

  .custom_parti_prev_slide {
    width: 40px !important;
    height: 40px !important;
    left: 2% !important;
    top: 43% !important;
  }

  .custom_parti_next_slide {
    width: 40px !important;
    height: 40px !important;
    right: 2% !important;
    top: 43% !important;
  }
  .custom_parti_prev_slide .content span {
    font-size: 15px !important;
  }
  .custom_parti_next_slide .content span {
    font-size: 15px !important;
  }
}

@media (max-width: 480px) {
  .plan_level_slider .level_details .fst_sec {
    padding: 20px !important;
  }
  .plan_level_slider .level_details .fst_sec .fst_row {
    /* flex-direction: column; */
  }
  .plan_level_slider .level_details .snd_row {
    flex-direction: column;
  }
  .plan_level_slider .level_details .cmn_bg {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    font-size: 13px !important;
  }

  .plan_level_slider .swiper-button-prev {
    width: 40px !important;
    height: 40px !important;
    left: 2% !important;
    top: 32%;
  }
  .plan_level_slider .swiper-button-prev::after {
    background-size: 40px 40px !important;
  }

  .plan_level_slider .swiper-button-next {
    width: 40px !important;
    height: 40px !important;
    right: 2% !important;
    top: 32%;
  }
  .plan_level_slider .swiper-button-next::after {
    background-size: 40px 40px !important;
  }
  .user_profile_info .snd_sec {
    padding: 10px 10px !important;
  }
  .user_profile_info .snd_sec .link {
    font-size: 15px !important;
  }
  .user_profile_info .snd_sec .copy_link_btn {
    font-size: 13px !important;
    padding: 8px 13px !important;
  }
  .user_profile_info .user_profile {
    width: 80px !important;
    height: 80px !important;
  }
  .user_profile_info .user_profile .userbadge_logo {
    width: 23px !important;
    height: 23px !important;
  }
  .user_profile_info .fst_sec .id_details .user_id {
    font-size: 19px !important;
  }
  .user_profile_info .fst_sec .id_details .hdn_code {
    font-size: 14px !important;
  }
  .dashboard_sec_content .recent_activity .heading {
    font-size: 18px !important;
  }
  .dashboard_sec_content .heading {
    font-size: 18px !important;
  }
  .card_section .cmn_card .total_count {
    font-size: 15px !important;
  }
  .admin_link_section .snd_sec {
    /* flex-direction: column !important; */
  }
  /* .admin_link_section .snd_sec .copy_link_btn{
    padding: 7px 14px !important;
    font-size: 14px !important;
  } */
  .admin_link_section .snd_sec {
    gap: 15px !important;
  }
  .plan_particular_content .heading {
    font-size: 15px !important;
    gap: 12px !important;
    font-weight: 600;
  }
  .plan_particular_content .heading .level {
    font-size: 15px !important;
  }
  .plan_level_slider {
    padding: 35px 0px !important;
  }
  .plan_level_slider .level_details .fst_row span {
    font-size: 18px !important;
  }
}

@media (max-width: 575px) {
  .plan_level_slider .level_details .fst_sec {
    padding: 20px !important;
  }
  .cont {
    margin-top: 80px !important;
  }

  .board_plan_sec .content_sec {
    padding: 15px 20px !important;
  }
  .board_plan_sec .content_sec .board_collection {
    padding: 15px !important;
  }
  .board_plan_sec .level_card .card-body {
    padding: 15px !important;
  }
  .board_plan_sec .level_card .plan_custom_navi {
    font-size: 16px !important;
  }
  .board_plan_sec .coin_logo {
    width: 18px !important;
    height: 18px !important;
  }
  .board_plan_sec .level_card .plan_custom_navi .foot_sec .reload_icon {
    width: 26px !important;
    height: 26px !important;
  }
  .board_plan_sec .level_card .plan_custom_navi .foot_sec .user_icon {
    width: 28px !important;
    height: 28px !important;
  }
  .board_plan_sec .body_sec .cmn_bg_color {
    width: 35px !important;
    height: 35px !important;
  }
  .board_plan_sec .body_sec .list {
    gap: 15px !important;
  }
  .board_plan_sec .owl-nav .owl-next {
    width: 50px !important;
    height: 50px !important;
  }
  .board_plan_sec .owl-nav .owl-prev {
    width: 50px !important;
    height: 50px !important;
  }
  .my_current_plan .card_section .plan_completed_sec {
    gap: 15px !important;
  }
  .dashboard_sec_content .sub_heding {
    font-size: 18px !important;
  }
  .dashboard_sec_content .sub_heding_val {
    font-size: 18px !important;
  }
  .plan_card_sec .locked_plan .plan_level {
    font-size: 20px !important;
  }
  .plan_card_sec .locked_plan .lock_logo {
    width: 25px !important;
    height: 25px !important;
  }
  .plan_card_sec .lock_lottie {
    width: 200px !important;
    height: 200px !important;
  }
  .plan_card_sec .unlock_btn {
    padding: 7px 26px !important;
  }
  .dashboard_sec_content .user_based_search {
    flex-wrap: unset !important;
    align-items: end !important;
  }
  .dashboard_sec_content .user_based_search {
    padding: 10px 10px !important;
  }
  .user_based_search .fst_sec .go_btn {
    height: 40px !important;
  }
  .user_based_search .fst_sec .form-control {
    height: 40px !important;
  }
  .dashboard_sec_content .user_based_search .logo {
    min-width: 42px;
    min-height: 42px;
    width: 42px;
    height: 42px;
  }
  .recent_activity .see_more_btn {
    padding: 10px !important;
    font-size: 15px !important;
  }
  .plan_level_slider .level_details .snd_row .inside_cnt {
    flex-direction: column;
    flex-wrap: unset !important;
  }
}

@media (max-width: 1200px) {
  .partner_total_profits {
    flex-direction: column !important;
    gap: 20px;
    padding: 15px !important;
  }
  .plan_particular_content_one .plan_level_slider .swiper-button-prev {
    top: 46% !important;
  }
  .plan_particular_content_one .plan_level_slider .swiper-button-next {
    top: 46% !important;
  }
  .plan_level_slider .level_details .snd_row {
    min-height: 140px !important;
  }
  .common_level_card {
    justify-content: center !important;
  }
}

@media (max-width: 480px) {
  .recent_activity .content_sec .user_id_btn {
    font-size: 13px !important;
    padding: 6px 10px !important;
  }
  .recent_activity .content_sec .txt {
    font-size: 14px !important;
  }
  .recent_activity .content_sec .time {
    font-size: 12px !important;
  }
  .recent_activity .user_detail .logo_sec .new_user {
    width: 20px !important;
    height: 20px !important;
  }
  .recent_activity .user_detail .logo_sec {
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
  }
  .recent_activity .content_sec {
    padding: 15px 10px !important;
  }
  .recent_activity .user_detail .logo_sec .wallet_logo {
    width: 19px;
    height: 19px;
  }
}

@media (max-width: 768px) {
  .dashboard_sec_content .personal_link_sec {
    display: unset !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .board_plan_sec .content_sec {
    padding: 26px 33px !important;
  }
  .board_plan_sec .content_sec .board_collection {
    padding: 25px !important;
  }
  .board_plan_sec .level_card .card-body {
    padding: 15px !important;
  }
  .board_plan_sec .level_card .plan_custom_navi {
    font-size: 20px !important;
  }
  .board_plan_sec .coin_logo {
    width: 20px !important;
    height: 20px !important;
  }
  .board_plan_sec .level_card .plan_custom_navi .foot_sec .reload_icon {
    width: 32px !important;
    height: 32px !important;
  }
  .board_plan_sec .level_card .plan_custom_navi .foot_sec .user_icon {
    width: 35px !important;
    height: 35px !important;
  }
  .board_plan_sec .body_sec .cmn_bg_color {
    width: 50px !important;
    height: 50px !important;
  }
  .board_plan_sec .body_sec .list {
    gap: 19px !important;
  }
}

@media (min-width: 1201px) and (max-width: 1440px) {
  .board_plan_sec .content_sec {
    padding: 26px 33px !important;
  }
  .board_plan_sec .content_sec .board_collection {
    padding: 25px !important;
  }
  .board_plan_sec .level_card .card-body {
    padding: 15px !important;
  }
  .board_plan_sec .level_card .plan_custom_navi {
    font-size: 25px !important;
  }
  .board_plan_sec .coin_logo {
    width: 20px !important;
    height: 20px !important;
  }
  .board_plan_sec .level_card .plan_custom_navi .foot_sec .reload_icon {
    width: 35px !important;
    height: 35px !important;
  }
  .board_plan_sec .level_card .plan_custom_navi .foot_sec .user_icon {
    width: 38px !important;
    height: 38px !important;
  }
  .board_plan_sec .body_sec .cmn_bg_color {
    width: 55px !important;
    height: 55px !important;
  }
  .board_plan_sec .body_sec .list {
    gap: 25px !important;
  }
  .board_plan_sec .body_sec .list {
    padding: 40px 0px !important;
  }
  .common_level_card {
    gap: 20px !important;
  }

  .custom_parti_prev_slide {
    width: 85px !important;
    height: 85px !important;
    left: 10% !important;
    top: 43% !important;
  }

  .custom_parti_next_slide {
    width: 85px !important;
    height: 85px !important;
    right: 10% !important;
    top: 43% !important;
  }
  .custom_parti_prev_slide .content span {
    font-size: 15px !important;
  }
  .custom_parti_next_slide .content span {
    font-size: 15px !important;
  }
}

@media (max-width: 480px) {
  .admin_link_section .snd_sec .copy_link_btn {
    padding: 7px 10px !important;
    font-size: 12px !important;
  }
  .admin_link_section .snd_sec .link {
    font-size: 15px !important;
  }
  .admin_link_section .snd_sec .personal_link_tx {
    font-size: 14px !important;
  }
  .admin_link_section .snd_sec {
    padding: 12px 14px !important;
  }

  .custom_next_slide {
    right: 2% !important;
  }
  .custom_prev_slide {
    width: 40px !important;
    height: 40px !important;
    left: 3px !important;
    top: 43% !important;
  }

  .custom_prev_slide .content span {
    font-size: 15px !important;
  }
  .custom_next_slide {
    width: 40px !important;
    height: 40px !important;
    right: 3px !important;
    top: 43% !important;
  }
  .custom_next_slide .content span {
    font-size: 15px !important;
  }

  .custom_parti_prev_slide {
    width: 40px !important;
    height: 40px !important;
    left: 2% !important;
    top: 43% !important;
  }

  .custom_parti_next_slide {
    width: 40px !important;
    height: 40px !important;
    right: 2% !important;
    top: 43% !important;
  }
  .custom_parti_prev_slide .content span {
    font-size: 15px !important;
  }
  .custom_parti_next_slide .content span {
    font-size: 15px !important;
  }
}
@media (max-width: 450px) {
  .plan_level_slider .level_details .fst_sec .fst_row {
    flex-direction: column !important;
  }
}
@media (max-width: 991px) {
  .tron {
    width: 24.5px !important;
    /* height: 20px !important; */
  }
  .trxtxt {
    font-size: 12px !important;
  }
  .wallet_border {
    margin-left: unset !important;
  }
  .admin_header_sec .header_rgt_search_sec {
    margin-left: 0px !important;
  }
  .tron_bg_ywl {
    width: 20px !important;
  }
  .tron_red {
    width: 20px;
  }
  .header_sbar {
    width: 130px !important;
    font-size: 14px !important;
  }
  .header_rgt_icon {
    width: 18px !important;
    height: 18px !important;
  }
  .admin_header_sec .header_rgt_search_sec .search_btn {
    padding: 5px 8px !important;
  }
  .admin_header_sec .header_rgt_search_sec .notification_btn .dropdown-toggle {
    padding: 5px 8px !important;
  }
  .admin_header_sec .header_rgt_search_sec .login_out_btn .dropdown-toggle {
    padding: 5px 8px !important;
  }
  .header_search .search_icon {
    font-size: 18px !important;
  }
  .header_search .close_icon {
    font-size: 23px !important;
  }
  .notification_btn .dropdown-menu[data-bs-popper] {
    position: absolute !important;
    right: -90px !important;
  }
  .login_out_btn .dropdown-menu[data-bs-popper] {
    position: absolute;
    right: -20px !important;
  }

  .custom_search_drop .dropdown-menu[data-bs-popper] {
    position: absolute;
    /* left: -25px !important; */
    left: -60px !important;
  }
  .custom_search_drop .dropdown-menu .list_items {
    font-size: 14px !important;
  }
  .notification_content_custom .inside_content_collec {
    width: 220px !important;
  }
}

@media (min-width: 992px) {
  .custom_header_social {
    display: none !important;
  }
}

@media (min-width: 767px) and (max-width: 1440px) {
  .plan_level_slider .level_details .snd_row .inside_cnt {
    flex-wrap: unset !important;
  }
  .plan_level_slider .level_details .cmn_bg {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    font-size: 14px !important;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .header_sbar {
    width: 75px !important;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .header_sbar {
    width: 100px !important;
  }
}
@media (min-width: 1201px) and (max-width: 1440px) {
  .header_sbar {
    width: 200px !important;
  }
}
/* start of hariharan css */ /* start of register modal */
.icn_crl {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: transparent;
  border: 2px solid;
}
.icn_crl i {
  font-size: 13px;
}
.icn_crl.grn_clr {
  color: var(--green-clr);
}
.icn_crl.red_clr {
  color: var(--red-clr);
}
.icn_crl.gry_clr {
  color: var(--gray-clr);
}
.grn_clr_txt {
  color: var(--green-clr);
  font-weight: 500;
}
.red_clr_txt {
  color: var(--red-clr);
  font-weight: 500;
}
.gry_clr_txt {
  color: var(--gray-clr);
  font-weight: 500;
}
.rgs_btn {
  text-decoration: none;
  background: var(--themeclr) !important;
  color: var(--textblkclr) !important;
  font-weight: 600;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid var(--themeclr) !important;
  transition: all 0.3s;
  box-shadow: none !important;
}
.rgs_btn:hover {
  background: transparent !important;
  color: var(--themeclr) !important;
}
.lgn_btn {
  text-decoration: none;
  color: var(--themeclr);
  transition: all 0.3s;
}
.lgn_btn:hover {
  color: var(--white);
}
.mdl_lbl {
  color: var(--gray-clr);
  font-weight: 500;
}
.mdl_inpt {
  background: var(--light-gry-clr);
  border: none;
  outline: none;
  height: 45px;
  color: var(--white);
}
.mdl_inpt::placeholder {
  color: var(--white);
}
.mdl_inpt:focus {
  box-shadow: none;
  color: var(--white);
  background-color: var(--light-gry-clr);
  /* border-color: #86b7fe; */
  outline: 0;
}
.mdl_heads {
  line-height: 1.5;
}
/* end of register modal */

.rct_cust_tltp.tooltip > div.tooltip-inner {
  background-color: #171717 !important;
  color: white !important;
}

.rct_cust_tltp.tooltip.show {
  opacity: 1 !important;
}

.rct_cust_tltp.tooltip .tooltip-arrow:before {
  border-bottom-color: #171717 !important;
  color: white !important;
}

/* end of hariharan css */
.form-control:disabled,
.form-control[readonly] {
  background-color: transparent !important;
  opacity: 1;
}
.custom_a_wht {
  color: white !important;
}

.amount_val_sm
{
  font-size: 12px !important;
    color: #f7bd02 !important;  
    word-break: break-all;
}